import anime from 'animejs';

export default class Xturheading {

	#content = [];
	#headingContainer;
	#h3node;

	constructor(selector) {
		this.#headingContainer = document.querySelector(selector);
	}

	getHeadingSelector() {
		return this.#headingContainer;
	}

	setContent(content) {
		this.#content = content;
	}

	createHeading() {
		if (!this.#headingContainer) return;
		this.#h3node = document.createElement('h3');
		this.#h3node.className = 'xtur-h3';
		this.#headingContainer.appendChild(this.#h3node);
		this.#animate();
	}

	changeText(elementIndex) {
		const text = document.createTextNode(this.#content[elementIndex]);
		this.#h3node.replaceChildren(text);
	}

	getTextWidth() {
		return Math.floor(this.#h3node.getBoundingClientRect().width) + 'px';
	}

	#animate() {
		const animation = anime
			.timeline({
				loop: true,
				easing: 'easeInOutQuint'
			});

		this.#content.forEach((e, index) => {
			this.changeText(index);
			animation.add(
				{
					targets: this.#headingContainer,
					duration: 2000,
					width: [0, this.getTextWidth()],
					delay: 1000,
					changeBegin: () => {
						this.changeText(index);
					}
				}
			);
			animation.add(
				{
					targets: this.#headingContainer,
					duration: 500,
					width: 0,
					delay: 2000,
				});
		})
	}

}
